import styled from '@emotion/styled'

const QuizContainer = styled.div`
  .leshen-columns {
    text-align: center;
    grid-template-columns: unset;
    grid-gap: 8px;
  }

  .leshen-column-wrapper > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    > * {
      margin-bottom: 0;
      margin-top: 0;
    }

    h1,
    h2,
    h3,
    h4 {
      color: ${(props) => `${props.theme.colors.dark}`} !important;
    }
  }

  .leshen-link-button,
  .leshen-link {
    min-width: 50%;
    margin: auto;

    &.previous {
      text-align: left;
      min-width: unset;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    .leshen-column-wrapper > div {
      text-align: left;
      align-items: flex-start;

      .leshen-link-button,
      .leshen-link {
        margin: unset;
      }
    }
  }
`

export default QuizContainer
