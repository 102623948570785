import React, { useState } from 'react'
import {
  LinkButton,
  Typography,
  useMapi,
  Column,
  Columns,
} from '@leshen/gatsby-theme-leshen'
import { StaticImage } from 'gatsby-plugin-image'

import QuizContainer from './Quiz.styles'

const Quiz = () => {
  const { rotatedNumber, requestId } = useMapi()
  const [question, setQuestion] = useState(1)
  const [showPhone] = useState(false)

  const handleAnswer = (answer) => {
    if (question === 1) {
      // Are you enrolled in Medicare yet?
      if (answer === 'No') {
        setQuestion(2)
      } else {
        setQuestion(3)
      }
    } else if (question === 2) {
      //  Learn more about Original Medicare
      if (answer === 'No') {
        setQuestion(2)
      } else {
        setQuestion(3)
      }
    } else if (question === 3) {
      // Do you need a new plan?
      if (answer === 'No') {
        setQuestion(4)
      } else {
        setQuestion(5)
      }
    }
  }

  const goBack = () => {
    setQuestion(1)
  }

  return (
    <QuizContainer>
      <Columns cardStyled>
        {question === 1 && (
          <Column>
            <Typography variant="h2" style={{ color: 'unset' }}>
              Are you enrolled in Medicare yet?
            </Typography>
            <LinkButton
              requestId={requestId}
              variant="feature"
              onClick={() => handleAnswer('No')}
            >
              No
            </LinkButton>
            <LinkButton
              requestId={requestId}
              variant="feature"
              onClick={() => handleAnswer('Yes')}
            >
              Yes
            </LinkButton>
          </Column>
        )}

        {question === 2 && (
          <Column>
            <Typography variant="h2" style={{ color: 'unset' }}>
              Learn more about Original Medicare
            </Typography>
            <LinkButton
              requestId={requestId}
              variant="feature"
              CustomLink=""
              to="https://www.medicare.gov/basics/get-started-with-medicare"
              color="primary"
            >
              Click Here
            </LinkButton>
            <span className="unstyled">
              <LinkButton
                requestId={requestId}
                onClick={goBack}
                className="previous leshen-link-button"
              >
                Previous
              </LinkButton>
            </span>
          </Column>
        )}

        {question === 3 && (
          <Column>
            <Typography variant="h2" style={{ color: 'unset' }}>
              Do you need a new plan?
            </Typography>

            <LinkButton
              requestId={requestId}
              variant="feature"
              onClick={() => handleAnswer('No')}
            >
              No
            </LinkButton>
            <LinkButton
              requestId={requestId}
              variant="feature"
              onClick={() => handleAnswer('Yes')}
            >
              Yes
            </LinkButton>

            <span className="unstyled">
              <LinkButton
                requestId={requestId}
                onClick={goBack}
                className="previous leshen-link-button"
              >
                Previous
              </LinkButton>
            </span>
          </Column>
        )}

        {question === 4 && (
          <Column>
            <Typography variant="h2" style={{ color: 'unset' }}>
              Speak with customer service
            </Typography>

            <LinkButton
              requestId={requestId}
              variant="feature"
              CustomLink=""
              to="tel:8553351407"
              color="primary"
            >
              1-855-335-1407
            </LinkButton>
          </Column>
        )}

        {question === 5 && (
          <>
            <Column>
              <Typography variant="h2" style={{ color: 'unset' }}>
                Tap to Talk with a licensed agent today
              </Typography>

              <LinkButton
                requestId={requestId}
                variant="feature"
                CustomLink=""
                to={`tel:${rotatedNumber}`}
                color="primary"
              >
                {rotatedNumber}
              </LinkButton>
              <Typography variant="p">TTY: 711, available 24/7</Typography>
            </Column>
            <Typography
              variant="h4"
              style={{ marginBottom: '0', textAlign: 'center' }}
            >
              OR...
            </Typography>
            <Column>
              {showPhone ? (
                <>
                  <Typography variant="h4" style={{ color: 'unset' }}>
                    Awaiting your call
                  </Typography>
                  <StaticImage
                    src="../../images/icons/flag.svg"
                    alt="star ratings"
                    layout="fixed"
                    width={100}
                    height={100}
                    className="product-icons"
                  />
                  <Typography variant="h4" style={{ color: '#7d3f98' }}>
                    <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>
                    <br />
                    TTY 711
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h2" style={{ color: 'unset' }}>
                    View plans available in your area
                  </Typography>

                  <LinkButton
                    requestId={requestId}
                    to="https://www.sunfirematrix.com/app/consumer/clearlink/18572496/"
                    CustomLink=""
                    variant="feature"
                    color="primary"
                  >
                    View Plans
                  </LinkButton>
                </>
              )}
            </Column>
          </>
        )}
      </Columns>
    </QuizContainer>
  )
}

export default Quiz
