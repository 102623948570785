/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import coupleDancing from '../../images/heroes/couple-dancing.jpg'

import { Hero, Typography } from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'
import Quiz from '../../components/Quiz'

import SeoCtaBar from '../../components/SeoCtaBar'

const Home = ({ data }) => {
  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-footer1',
    'disclaimer-amd-medicareadvantagelp',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: ' Get a medicare quote today! ',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/lp/phone',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: ' Get a medicare quote today! ',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero background-image-position-top"
              image={
                <img
                  src={coupleDancing}
                  alt="elderly couple dancing together in kitchen and smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="light">
                    Making Medicare Easy{' '}
                  </Typography>
                  <Quiz />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
